import React from 'react';
import './App.css';

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <h1>SDR Mate</h1>
        <p>Automate Your Sales Outreach with AI. Save Time, Close More Deals.</p>
        <button className="cta-button">Get Started</button>
      </header>
      <section className="features">
        <h2>Why SDR Mate?</h2>
        <p>Transform your sales pipeline with AI-driven automation. SDR Mate ensures your messages reach the right people at the right time with personalized content, increasing engagement and accelerating growth.</p>
      </section>
      <section className="integrations">
        <h2>Seamless Integrations</h2>
        <p>Connect effortlessly with your CRM, LinkedIn, and other tools to streamline outreach and follow-ups.</p>
      </section>
      <section className="contact">
        <h2>Ready to Start?</h2>
        <form>
          <input type="text" placeholder="Your Name" required />
          <input type="email" placeholder="Your Email" required />
          <button type="submit">Schedule a Demo</button>
        </form>
      </section>
      <footer className="app-footer">
        <p>&copy; 2024 SDR Mate | All Rights Reserved</p>
      </footer>
    </div>
  );
}

export default App;
